<template>
    <v-row no-gutters align="center" justify="center">
        <v-col cols="12" :class="{'col-xl-9 col-lg-9 col-md-12 col-sm-6': !openPanel}" class="pa-3">
            <v-expansion-panels>
                <v-expansion-panel @click="openPanel = !openPanel;menu=false">
                    <v-expansion-panel-header v-slot="{ open }">
                        <v-row no-gutters :class="open ? 'px-4' : 'px-2'">
                            <v-col cols="12" :class="open ? 'text-h6 font-weight-bold' : 'd-sm-flex d-lg-flex d-xl-flex font-weight-bold col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'">
                                {{ open ? 'Advance Filter' : 'Expand to Filter' }}
                            </v-col>
                            <v-col cols="8" class="d-none d-md-flex d-lg-flex d-xl-flex text--secondary">
                                <v-fade-transition leave-absolute>
                                    <v-row v-if="!open" no-gutters class="w-100">
                                        <v-col class="text-center col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                            Agent: {{ agentName || 'Not set' }}
                                        </v-col>
                                        <v-col class="text-center col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                            Client: {{ clientName || 'Not set' }}
                                        </v-col>
                                        <v-col class="text-center col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                            Location: {{ locationName || 'Not set' }}
                                        </v-col>
                                    </v-row>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-form lazy-validation @submit.prevent="$emit('search', model)">
                            <v-row class="px-5 pb-0">
                                <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                                    <v-select dense v-model="model.agent_id" :items="agentsList" outlined placeholder="---Select Agent---"
                                        :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                        hide-details="auto" clearable @click:clear="$emit('remove', 'agent_id')"
                                        @change="model.agent_name = agentName">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                                    <v-select dense v-model="model.client_id" :items="clientsList" outlined placeholder="---Select Client---"
                                        :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                        hide-details="auto" clearable @click:clear="$emit('remove', 'client_id')">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                                    <v-select dense v-model="model.location_id" :items="locationsList" outlined placeholder="---Select Location---"
                                        :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                                        hide-details="auto" clearable @click:clear="$emit('remove', 'location_id')"
                                        @change="model.location_name = locationName">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                                    <v-menu
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        left
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details
                                                dense
                                                placeholder="---Select Date---"
                                                style="background-color: white;"
                                                outlined
                                                v-model="model.date"
                                                label=""
                                                readonly
                                                clearable @click:clear="$emit('remove', 'date')"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker range v-model="model.date" @input="closeDateModal('menu1')"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" class="col-md-6">
                                    <v-btn type="submit" color="primary"  class="mr-4 mt-2" :loading="loading"
                                        :disabled="loading || !Object.keys(model).some(key => model[key])">
                                        Search
                                    </v-btn>
                                    <v-btn type="button" outlined color="primary" class="mr-4 mt-2" @click="resetForm"
                                        :disabled="loading">
                                        Reset
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-col v-if="!openPanel" class="pa-3 col-xs-12 col-sm-6 col-md-12 col-lg-3 col-xl-3">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                left
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field hide-details
                        dense
                        placeholder="---Select Date---"
                        style="background-color: white;"
                        outlined
                        v-model="model.date"
                        label=""
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker range v-model="model.date" @input="closeDateModal('menu')" @change="$emit('search', model)"></v-date-picker>
            </v-menu>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['loading'],
    emits: [ 'search', 'reset', 'remove'],
    data: (vm) => ({
        model: {
            date: null,
            agent_id: null,
            client_id: null,
            location_id: null,
        },
        reseted: false,
        openPanel: false,
        menu: false,
        menu1: false
    }),
    computed: {
        ...mapGetters(['locationsList', 'agentsList', 'clientsList']),

        agentName() {
            const agent = _.find(this.agentsList, item => item.value === this.model.agent_id);
            return this.model.agent_id && agent ? agent.text : null;
        },
        clientName() {
            const client = _.find(this.clientsList, item => item.value === this.model.client_id);
            return this.model.client_id && client ? client.text : null;
        },
        locationName() {
            const location = _.find(this.locationsList, item => item.value === this.model.location_id);
            return this.model.location_id && location ? location.text : null;
        },
    },
    created() {
        this.getLocationLists();
        this.getAgentLists();
        this.getClientLists();
    },
    methods: {
        ...mapActions(['getLocationLists', 'getAgentLists', 'getClientLists']),

        resetForm() {
            this.reseted = true;
            this.$emit('reset');
            this.model = {
                date: null,
                agent_id: null,
                client_id: null,
                location_id: null,
            },
            setTimeout(() => {
                this.reseted = false;
            });
        },
        closeDateModal(field){
            if (this.model.date.length === 2) this[field] = false;
        }
    }
}
</script>
